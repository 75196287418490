import { createAction, props } from '@ngrx/store';
import {
  Pair,
  Instruction,
  SendInstructionRequest,
  InstructionRequestPagedResult,
} from '@paldesk/shared-lib/data-access/provisioning-service-generated';
import { OverviewQuery } from '../../models';

export namespace CommunicationActions {
  export const LoadAssignedEquipments = createAction(
    '[TELEMATIC-LAB] LOAD_ASSIGNED_EQUIMENTS',
    props<{ payload: string }>(),
  );
  export const LoadAssignedEquipmentsSuccess = createAction(
    '[TELEMATIC-LAB] LOAD_ASSIGNED_EQUIMENTS_SUCCESS',
    props<{ payload: Pair[] }>(),
  );
  export const LoadAssignedEquipmentsError = createAction(
    '[TELEMATIC-LAB] LOAD_ASSIGNED_EQUIMENTS_ERROR',
  );

  export const LoadInstructions = createAction(
    '[TELEMATIC-LAB] LOAD_INSTRUCTIONS',
  );
  export const LoadInstructionsSuccess = createAction(
    '[TELEMATIC-LAB] LOAD_COMMANDS_SUCCESS',
    props<{ payload: Instruction[] }>(),
  );
  export const LoadInstructionsError = createAction(
    '[TELEMATIC-LAB] LOAD_COMMANDS_ERROR',
  );

  export const PostInstructions = createAction(
    '[TELEMATIC-LAB] POST_INSTRUCTIONS',
    props<{ payload: SendInstructionRequest; email: string }>(),
  );
  export const PostInstructionsSuccess = createAction(
    '[TELEMATIC-LAB] POST_COMMANDS_SUCCESS',
  );
  export const PostInstructionsError = createAction(
    '[TELEMATIC-LAB] POST_COMMANDS_ERROR',
  );

  export const LoadRequestOverview = createAction(
    '[TELEMATIC-LAB] LOAD_REQUEST_OVERVIEW',
    props<{ payload: string }>(),
  );
  export const SaveRequestOverviewQuery = createAction(
    '[TELEMATIC-LAB] SAVE_REQUEST_OVERVIEW_QUERY',
    props<{ payload: OverviewQuery }>(),
  );
  export const LoadRequestOverviewsSuccess = createAction(
    '[TELEMATIC-LAB] LOAD_REQUEST_OVERVIEW_SUCCESS',
    props<{ payload: InstructionRequestPagedResult }>(),
  );
  export const LoadRequestOverviewError = createAction(
    '[TELEMATIC-LAB] LOAD_REQUEST_OVERVIEW_ERROR',
  );
}
