import { Injectable, inject } from '@angular/core';
import { DsSnackbarType } from '@design-system/feature/snackbar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { LabService } from '@paldesk/shared-lib/data-access/provisioning-service-generated';
import { Observable } from 'rxjs';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { TelematicLabState } from '..';
import { RootActions } from '../root/root.actions';
import { RootSelectors } from '../root/root.selectors';
import { CommunicationActions } from './communication.actions';
import { CommunicationSelectors } from './communication.selectors';

@Injectable()
export class CommunicationEffects {
  private actions$: Actions = inject(Actions);
  private store: Store<TelematicLabState> = inject(Store);

  LoadAssignedEquipments$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CommunicationActions.LoadAssignedEquipments),
      switchMap((payload) =>
        this.communicationService.getAssignedPairs(payload.payload).pipe(
          map((data) =>
            CommunicationActions.LoadAssignedEquipmentsSuccess({
              payload: data,
            }),
          ),
          catchError(() => [
            CommunicationActions.LoadAssignedEquipmentsError(),
            RootActions.ShowErrorSnackbar(),
          ]),
        ),
      ),
    ),
  );

  LoadInstructions$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CommunicationActions.LoadInstructions),
      switchMap(() =>
        this.communicationService.getInstructions().pipe(
          map((data) =>
            CommunicationActions.LoadInstructionsSuccess({
              payload: data,
            }),
          ),
          catchError(() => [
            CommunicationActions.LoadInstructionsError(),
            RootActions.ShowErrorSnackbar(),
          ]),
        ),
      ),
    ),
  );

  PostInstructions$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CommunicationActions.PostInstructions),
      switchMap((payload) =>
        this.communicationService.sendInstruction(payload.payload).pipe(
          mergeMap(() => [
            CommunicationActions.PostInstructionsSuccess(),
            RootActions.ShowSnackbar({
              message: this.translatePipe.instant(
                'telematic_lab.message.request_succeeded',
              ),
              snackbar_type: DsSnackbarType.Success,
            }),
            CommunicationActions.LoadRequestOverview({
              payload: payload.email,
            }),
          ]),
          catchError(() => [
            CommunicationActions.PostInstructionsError(),
            RootActions.ShowErrorSnackbar(),
          ]),
        ),
      ),
    ),
  );

  LoadRequestOverview$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CommunicationActions.LoadRequestOverview),
      withLatestFrom(
        this.store.select(CommunicationSelectors.getRequestOverviewQuery),
      ),
      switchMap(([payload, query]) =>
        this.communicationService
          .getRequestsOverview(payload.payload, query.page, query.pageSize)
          .pipe(
            map((data) =>
              CommunicationActions.LoadRequestOverviewsSuccess({
                payload: data,
              }),
            ),
            catchError(() => [
              CommunicationActions.LoadRequestOverviewError(),
              RootActions.ShowErrorSnackbar(),
            ]),
          ),
      ),
    ),
  );

  $saveRequestOverviewQuery: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CommunicationActions.SaveRequestOverviewQuery),
      withLatestFrom(this.store.select(RootSelectors.getUsersMail)),
      switchMap(([, mail]) => [
        CommunicationActions.LoadRequestOverview({
          payload: mail,
        }),
      ]),
    ),
  );

  constructor(
    private communicationService: LabService,
    private translatePipe: TranslateService,
  ) {}
}
