import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { OverviewQuery } from '../../models';
import {
  Instruction,
  InstructionRequestPagedResult,
  Pair,
} from '@paldesk/shared-lib/data-access/provisioning-service-generated';
import { CommunicationActions } from './communication.actions';

export interface CommunicationState extends EntityState<any> {
  assignedEquipments: Pair[];
  isAssignedEquipmentsLoading: boolean;
  instructions: Instruction[];
  isInstructionsLoading: boolean;
  requestOverview: InstructionRequestPagedResult;
  requestOverviewQuery: OverviewQuery;
  isRequestOverviewLoading: boolean;
  isRequestPending: boolean;
  hasOverview: boolean;
}

export const adapter: EntityAdapter<any> = createEntityAdapter<any>({});

export const initialState: CommunicationState = adapter.getInitialState({
  assignedEquipments: [] as Pair[],
  isAssignedEquipmentsLoading: true,
  instructions: [] as Instruction[],
  isInstructionsLoading: true,
  requestOverview: {} as InstructionRequestPagedResult,
  requestOverviewQuery: { page: 0, pageSize: 50 } as OverviewQuery,
  isRequestOverviewLoading: true,
  isRequestPending: false,
  hasOverview: false,
});

const _communicationReducer = createReducer(
  initialState,
  on(CommunicationActions.LoadAssignedEquipments, (state) => ({
    ...state,
    isAssignedEquipmentsLoading: true,
  })),
  on(
    CommunicationActions.LoadAssignedEquipmentsSuccess,
    (state, { payload }) => ({
      ...state,
      assignedEquipments: payload,
      isAssignedEquipmentsLoading: false,
    }),
  ),
  on(CommunicationActions.LoadAssignedEquipmentsError, (state) => ({
    ...state,
    isAssignedEquipmentsLoading: false,
  })),

  on(CommunicationActions.LoadInstructions, (state) => ({
    ...state,
    isInstructionsLoading: true,
  })),
  on(CommunicationActions.LoadInstructionsSuccess, (state, { payload }) => ({
    ...state,
    instructions: payload,
    isInstructionsLoading: false,
  })),
  on(CommunicationActions.LoadInstructionsError, (state) => ({
    ...state,
    isInstructionsLoading: false,
  })),

  on(CommunicationActions.SaveRequestOverviewQuery, (state, { payload }) => ({
    ...state,
    requestOverviewQuery: payload,
  })),
  on(CommunicationActions.LoadRequestOverview, (state) => ({
    ...state,
    isRequestOverviewLoading: true,
  })),
  on(
    CommunicationActions.LoadRequestOverviewsSuccess,
    (state, { payload }) => ({
      ...state,
      requestOverview: payload,
      isRequestOverviewLoading: false,
      hasOverview:
        payload?.results === null ? false : (payload?.results?.length || 0) > 0,
    }),
  ),
  on(CommunicationActions.LoadRequestOverviewError, (state) => ({
    ...state,
    isRequestOverviewLoading: false,
  })),

  on(CommunicationActions.PostInstructions, (state) => ({
    ...state,
    isRequestPending: true,
  })),
  on(CommunicationActions.PostInstructionsSuccess, (state) => ({
    ...state,
    isRequestPending: false,
  })),
  on(CommunicationActions.PostInstructionsError, (state) => ({
    ...state,
    isRequestPending: false,
  })),
);
export function communicationReducer(
  state,
  action: Action,
): CommunicationState {
  return _communicationReducer(state, action);
}
