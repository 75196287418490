import { createSelector } from '@ngrx/store';
import { TelematicLabState } from '..';

export namespace CommunicationSelectors {
  const getCommunicationState = (state: TelematicLabState) =>
    state.communication;

  export const getAssignedEquipments = createSelector(
    getCommunicationState,
    (state) => state.assignedEquipments,
  );
  export const getIsAssignedEquipmentsLoading = createSelector(
    getCommunicationState,
    (state) => state.isAssignedEquipmentsLoading,
  );

  export const getInstructions = createSelector(
    getCommunicationState,
    (state) => state.instructions,
  );
  export const getIsInstructionsLoading = createSelector(
    getCommunicationState,
    (state) => state.isInstructionsLoading,
  );

  export const getRequestOverview = createSelector(
    getCommunicationState,
    (state) => state.requestOverview,
  );
  export const getRequestOverviewQuery = createSelector(
    getCommunicationState,
    (state) => state.requestOverviewQuery,
  );
  export const getHasRequestOverview = createSelector(
    getCommunicationState,
    (state) => state.hasOverview,
  );
  export const getIsRequestOverviewLoading = createSelector(
    getCommunicationState,
    (state) => state.isRequestOverviewLoading,
  );
  export const getIsRequestPending = createSelector(
    getCommunicationState,
    (state) => state.isRequestPending,
  );
}
